<template>
  <b-container fluid>
    <b-card
      bg-variant="light"
      ref="stickyFilters"
      class="my-3"
      :style="stickyStyles"
    >
      <b-row>
        <b-col md="2">
          <b-form-group :label="$t('jobs.filter.filter') + ':'">
            <b-input-group>
              <b-form-input
                v-e2e:filterSearch
                v-model="filterSearch"
                debounce="400"
                :placeholder="$t('jobs.filter.filter-placeholder')"
              />
              <b-input-group-append v-if="filterSearch">
                <b-btn v-e2e:filterSearchButton @click="filterSearch = ''">
                  &times;
                </b-btn>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            :label="
              $t('jobs.filter.date-from') +
              ' - ' +
              $t('jobs.filter.date-to') +
              ':'
            "
          >
            <div class="d-flex">
              <date-picker v-e2e:filterDateFrom v-model="filterDateFrom" />
              <date-picker
                :end="true"
                v-e2e:filterDateTo
                v-model="filterDateTo"
                class="ml-2"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group :label="$t('jobs.filter.status') + ':'">
            <multiselect
              v-model="filterStatus"
              :options="statusesGroupedByName"
              :multiple="true"
              track-by="value"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="2" v-if="contactProfiles.includes('admin')">
          <b-form-group :label="$t('jobs.filter.project-manager') + ':'">
            <b-form-select
              v-model="filterProjectManager"
              :options="projectManagers"
            >
              <template slot="first">
                <option :value="null">-- {{ $t('all') }} --</option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group class="mb-0">
            <template slot="label">
              {{ $t('jobs.file-package.label') }}:
            </template>
            <b-btn
              id="download-report-btn"
              v-e2e:downloadReportButton
              block
              @click="downloadReport"
              :disabled="downloadDisabled"
            >
              <b-spinner
                v-if="exportState == 'loading'"
                class="gid-spinner--button mr-2"
              ></b-spinner>
              {{ $t('jobs.file-package.generate') }}
            </b-btn>
            <b-popover
              target="download-report-btn"
              placement="bottom"
              :variant="exportState"
            >
              <template #title>
                {{ $t(`projects.file-package.action-${exportState}`) }}
              </template>
              {{
                $t(
                  exportState == 'success'
                    ? 'jobs.file-package.explain'
                    : exportError,
                )
              }}
            </b-popover>
            <small class="text-muted">
              {{ $t('jobs.file-package.count', { totalRows }) }}
            </small>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group>
            <b-form-checkbox
              class="pt-1"
              v-e2e:filterUseAdvancedSearch
              v-model="filterUseAdvancedSearch"
            >
              {{ $t('filters.advanced.toggle') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <advanced-search
        v-if="filterUseAdvancedSearch"
        v-model="filterAdvancedSearch"
        :filter-options="advancedFilterOptions"
      />
    </b-card>
    <b-table
      v-e2e:jobsTable
      :show-empty="!loading"
      striped
      stacked="md"
      :items="items"
      class="gid-table-infinite"
      ref="wrapper"
      :fields="computedFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDir"
      no-local-sorting
    >
      <template #cell(_brand_ids)="{ item }">
        <template v-if="item.job.eid">
          <a
            :href="`#edit-eid-${item.job.sfid}`"
            @click.prevent="
              editEID(item.job.sfid, item.job.eid, InputTypes.ORDER_EID)
            "
            v-if="canEditOrderEID"
          >
            {{ item.job.eid }}
          </a>
          <span v-else>{{ item.job.eid }}</span>
        </template>
        <span v-else>{{ item.job.name }}</span>
        <br />
        <span
          v-if="!item.customer.eid && !item.customer_number_editing"
          @click="editCustomerEid(item)"
          class="text-primary"
          role="button"
        >
          {{ $t('customer-number-add') }}
        </span>
        <span v-if="item.customer.eid && !item.customer_number_editing">
          / {{ item.customer.eid }}
          <i
            class="material-icons gid-brand-eid-edit"
            role="button"
            @click="editCustomerEid(item)"
          >
            edit
          </i>
        </span>
        <template v-if="item.customer_number_editing">
          <b-form-input
            v-model="customerEid"
            :disabled="item.customer_number_loading"
            :placeholder="$t('inputs.customer.eid')"
          ></b-form-input>
          <div class="my-2">
            <b-button
              @click="setCustomerEid(item.job.api_id, item)"
              :disabled="item.customer_number_loading"
              class="p-1"
            >
              <b-spinner
                v-if="item.customer_number_loading"
                class="gid-spinner--button mr-2"
              ></b-spinner>
              <i class="material-icons">done</i>
            </b-button>

            <b-button
              @click="cancelEidEditing(item)"
              :disabled="item.customer_number_loading"
              class="p-1 mx-2"
            >
              <i class="material-icons">close</i>
            </b-button>
          </div>
        </template>
        <a
          v-if="item.job.invoicing_eid"
          :href="`#edit-invoice-eid-${item.job.sfid}`"
          class="text-muted d-block text-break"
          @click.prevent="
            editEID(
              item.job.sfid,
              item.job.invoicing_eid,
              InputTypes.INVOICING_EID,
            )
          "
        >
          {{ item.job.invoicing_eid }}
        </a>
        <RatingWidget context="job" v-bind="jobRatings[item.job.api_id]" />
        <div v-if="shouldDisplayQuote(item)">
          <a href="#" @click.prevent="getQuotePath(item.job.sfid)">
            {{ $t('view-quote') }}
          </a>
        </div>
        <div v-if="shouldDisplayBrandQuote(item)">
          <a href="#" @click.prevent="getBrandQuotePath(item.job.sfid)">
            {{ $t('view-brand-quote') }}
          </a>
        </div>
        <b-badge v-if="hasOwnInstaller(item)" variant="dark">
          {{ $t(`job.has-own-installer`) }}
        </b-badge>
      </template>
      <template #cell(project.name)="{ item }">
        {{ item.opportunity.name }}
        <small class="d-block text-muted">{{ item.project.name }}</small>
      </template>
      <template #cell(customer._fullname)="{ item }">
        <div>{{ item.customer.name }}</div>
        <div>{{ getCustomerShippingAddress(item) }}</div>
        <div v-if="item.customer.company_name" class="text-muted">
          <small>{{ item.customer.company_name }}</small>
        </div>
      </template>
      <template #cell(job.status)="{ value, item }">
        {{ statusesById[value] }}
        <div v-for="(status, idx) in compoundStateItems(item)" :key="idx">
          <b-badge
            variant="warning"
            class="text-wrap"
            v-b-tooltip.hover
            :title="status.details"
          >
            {{ status.name }}
          </b-badge>
        </div>
      </template>
      <template #cell(job._price)="{ item }">
        <price-details :item="item" :cancellations="cancellations" />
      </template>
      <template #cell(_actions)="{ item, toggleDetails }">
        <b-button size="sm" class="mr-1" @click.stop="toggleDetails">
          {{ $t('jobs.details') }}
        </b-button>
        <b-button
          variant="light"
          size="sm"
          @click.stop="$refs.logView.showLog(item.job.sfid)"
        >
          Logs
        </b-button>
        <div class="my-3" v-if="shouldDisplayBrandActions(item)">
          <p>
            <strong>{{ $t('quote.actions') }}</strong>
            :
          </p>
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click.stop="acceptQuote(item)"
            :disabled="quote.saving"
          >
            <b-spinner small v-if="quote.saving"></b-spinner>
            {{ $t('quote.accept') }}
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click.stop="rejectQuote(item.job.sfid)"
            :disabled="quote.saving"
          >
            <b-spinner small v-if="quote.saving"></b-spinner>
            {{ $t('quote.reject') }}
          </b-button>
        </div>
        <b-dropdown
          right
          size="sm"
          text="Docs"
          class="mr-1"
          v-if="jobHasDocs(item)"
        >
          <b-dropdown-item
            v-if="item.files.picture_before"
            :href="`/api/media/file/${item.files.picture_before.paths[0]}?jwt=${access_token}`"
            target="_blank"
          >
            {{ $t('jobs.image.start') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="item.files.picture_after"
            :href="`/api/media/file/${item.files.picture_after.paths[0]}?jwt=${access_token}`"
            target="_blank"
          >
            {{ $t('jobs.image.finish') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="item.files.protocol"
            :href="`/api/media/file/${item.files.protocol.paths[0]}?jwt=${access_token}`"
            target="_blank"
          >
            {{ $t('jobs.image.protocol') }}
          </b-dropdown-item>
          <template v-if="item.files.picture">
            <b-dropdown-item
              v-for="(path, index) in item.files.picture.paths"
              :key="`picture-${index}`"
              :href="`/api/media/file/${path}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('jobs.image.picture', { index }) }}
            </b-dropdown-item>
          </template>
          <template v-if="item.files.planning_customer">
            <b-dropdown-item
              v-for="(path, index) in item.files.planning_customer.paths"
              :key="`customer-${index}`"
              :href="`/api/media/file/${path}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('jobs.image.planning_customer', { index }) }}
            </b-dropdown-item>
          </template>
          <template v-if="item.files.planning_partner">
            <b-dropdown-item
              v-for="(path, index) in item.files.planning_partner.paths"
              :key="`partner-${index}`"
              :href="`/api/media/file/${path}?jwt=${access_token}`"
              target="_blank"
            >
              {{ $t('jobs.image.planning_partner', { index }) }}
            </b-dropdown-item>
          </template>
        </b-dropdown>
        <b-checkbox
          v-if="shouldBeAbleToInvoice(item)"
          :disabled="item.job.invoiced_by_brand__c"
          :checked="item.job.invoiced_by_brand__c"
          @change="invoiceOrder(item.job.sfid)"
        >
          {{ $t('invoice') }}
        </b-checkbox>
      </template>
      <template #cell(project_manager)="{ item }">
        <span
          v-if="
            !contactProfiles.includes('admin') && item.project_owners.length
          "
        >
          <span v-for="pm in item.project_owners" :key="pm">
            {{ `${pm.first_name} ${pm.last_name}` }}
          </span>
        </span>
        <span
          v-if="
            !contactProfiles.includes('admin') && !item.project_owners.length
          "
        >
          None
        </span>
        <project-manager-dropdown
          :value="selectedProjectManager(item) || ''"
          :projectId="item.project.id"
          :setProjectManager="setProjectManager"
          :projectManagers="projectManagers"
          v-if="contactProfiles.includes('admin')"
        />
      </template>
      <template #cell(days_since_last_status_change)="{ item }">
        <DaysSinceLastChange :days="item.days_since_last_status_change" />
      </template>
      <template #cell(car_delivery)="{ item }">
        <p>
          {{
            item.properties && item.properties.car_delivery
              ? $moment(item.properties.car_delivery).format('L')
              : '-'
          }}
        </p>
        <small
          v-if="getCarTypeExist(item)"
          class="text-muted d-block text-break"
        >
          {{ $t('type') }}: {{ getCarType(item.properties.car_type) }}
        </small>
        <small
          v-if="getCarChargingTypeExist(item)"
          class="text-muted d-block text-break"
        >
          {{ $t('charging-opportunity') }}:
          {{ getChargingType(item.properties.charging_opportunity) }}
        </small>
        <p class="d-flex">
          <DaysSinceLastChange
            v-if="item.dkv_wallbox_priority_score"
            class="ml-3"
            :days="item.dkv_wallbox_priority_score"
          />
          <span
            class="mx-2"
            v-if="getUrgentReason(item)"
            v-b-tooltip.hover
            :title="getUrgentReason(item)"
          >
            ⚠️
          </span>
        </p>
      </template>
      <template #cell(job.appointment_start_timestamp)="{ item }">
        {{
          item.job.appointment_start_timestamp ? getTimeRange(item.job) : '-'
        }}
        <br />
        {{
          greaterDateFormatted(item.job.readiness_date, item.job.date_expected)
        }}
        <br />
        <strong>
          {{ latestStatusChangeFormatted(item) }}
          {{ item.status_description.brand_name[locale] }}
        </strong>
        <div v-for="(status, idx) in compoundStateItems(item)" :key="idx">
          <b-badge variant="warning">{{ status }}</b-badge>
        </div>
        <div class="d-flex">
          <DaysSinceLastChange
            v-if="statusNotCompleted(item)"
            :days="item.days_since_last_status_change"
          />
          <DaysSinceLastChange
            v-if="item.actions_taken"
            class="ml-3"
            context="action"
            :days="item.actions_taken"
            :action_date="item.latest_action"
          />
        </div>
      </template>
      <template #row-details="{ item }">
        <job-details :item="item" @refresh="refreshJob(item.job.sfid)" />
      </template>
      <template #custom-foot="{ columns }" v-if="loading">
        <b-tr v-for="row in perPage" :key="row">
          <b-td v-for="col in columns" :key="col">
            <b-skeleton :width="`${Math.round(Math.random() * 50) + 50}%`" />
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <div class="gid-results-count__holder d-flex">
      <b-button
        pill
        v-if="stickyFiltersButton"
        @click="toggleSitckyFilters"
        :variant="stickyFiltersVisible ? 'primary' : 'secondary'"
        class="mr-2"
      >
        <BIconSliders />
      </b-button>
      <div class="rounded-pill gid-results-count">
        <span :class="{ 'gid-results-count--loading': loading }">
          <template v-if="totalRows !== null">
            {{ $t('results') }}: {{ items.length }} / {{ totalRows || 0 }}
          </template>
          <BIconThreeDots v-else />
        </span>
      </div>
    </div>
    <b-modal ref="orderEidModal" :title="`Enter new eid`" @ok="saveOrderEID">
      <b-form-group :label="edit_eid_modal.label">
        <b-form-input v-model="edit_eid_modal.eid" />
      </b-form-group>
    </b-modal>
    <log-list ref="logView" :statuses="statuses" :context="'brand'" />
  </b-container>
</template>

<script>
import axios from 'axios';
import Api from '@gid/vue-common/api';
import { mapGetters } from 'vuex';
import { mapFilters } from '@gid/vue-common/store/utils';
import * as InputTypes from '@gid/vue-common/components/inputs/_types';
import { SET_REPORTS_FILTER } from '@gid/vue-common/store/reports.module';
import LogList from '@gid/vue-common/components/LogList';

import PriceDetails from './PriceDetails.vue';
import DatePicker from '@gid/vue-common/components/DatePicker.vue';
import JobDetails from './JobDetails';
import ProjectManagerDropdown from '../components/ProjectManagerDropdown.vue';
import RatingWidget from '@gid/vue-common/components/RatingWidget.vue';
import AdvancedSearch from '@gid/vue-common/components/filters/AdvancedSearch.vue';
import DaysSinceLastChange from '@gid/vue-common/components/DaysSinceLastChange';
import InfiniteScrollMixin from '@gid/vue-common/components/mixins/InfiniteScrollMixin';
import { mapDetails } from '@gid/vue-common/components/activity/StateActivity.vue';
import AdvancedFilterOptionsMixin from '../mixins/AdvancedFilterOptionsMixin';
import { JobStatusEnum, AccountProfileEnum } from '@gid/models';
import { BIconSliders, BIconThreeDots } from 'bootstrap-vue';

export default {
  mixins: [AdvancedFilterOptionsMixin, InfiniteScrollMixin],
  components: {
    AdvancedSearch,
    DatePicker,
    JobDetails,
    LogList,
    PriceDetails,
    ProjectManagerDropdown,
    RatingWidget,
    DaysSinceLastChange,
    BIconSliders,
    BIconThreeDots,
  },
  props: {
    job_id: String,
  },
  data() {
    return {
      fields: [
        { label: this.$t('jobs.table.heading.id'), key: '_brand_ids' },
        {
          label: this.$t('jobs.table.heading.project-id'),
          key: 'job.project_id',
        },
        {
          label: this.$t('jobs.table.heading.opportunity'),
          key: 'opportunity.name',
        },
        {
          label: this.$t('jobs.table.heading.order-value'),
          key: 'job._price',
        },
        {
          label: this.$t('jobs.table.heading.customer'),
          key: 'customer._fullname',
        },
        {
          label: this.$t('jobs.table.heading.created-date'),
          key: 'job.created_date',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        {
          label: this.$t('jobs.table.heading.appointment'),
          key: 'job',
          formatter: (value) =>
            value.appointment_start_timestamp ? this.getTimeRange(value) : '-',
        },
        { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
        {
          label: this.$t('jobs.table.heading.documentation'),
          key: '_actions',
          tdClass: 'text-nowrap',
        },
      ],
      items: [],
      perPage: 10,
      totalRows: null,
      sortBy: null,
      sortDir: false,
      loading: 0,
      statuses: [],
      cancellations: {},
      exportState: 'available',
      exportError: null,
      edit_eid_modal: {
        label: null,
        field: null,
        job_id: null,
        eid: null,
      },
      projectManagers: [],
      jobRatings: {},
      InputTypes,
      quote: {
        saving: false,
      },
      customerEid: '',
    };
  },
  computed: {
    ...mapGetters([
      'locale',
      'contactProfiles',
      'user',
      'access_token',
      'shouldShowProposalMenuItems',
    ]),
    ...mapFilters({
      moduleName: 'reports',
      states: [
        'search',
        'dateFrom',
        'dateTo',
        'status',
        'projectManager',
        'useAdvancedSearch',
        'advancedSearch',
      ],
      setMutation: SET_REPORTS_FILTER,
      setCallback: 'filterUpdated',
    }),
    statusNotCompleted() {
      return (item) => item?.job?.status != JobStatusEnum.COMPLETED;
    },
    shouldDisplayWallboxScore() {
      return (item) => {
        const notInStatuses = [
          JobStatusEnum.CLOSED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.FINISHED,
          JobStatusEnum.CANCELLED,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.COMPLETED,
        ];
        if (
          item?.productOrder &&
          item.productOrder.status != 'all' &&
          !notInStatuses.includes(item.job.status)
        ) {
          if (!item?.is_precheck && !item?.is_activation) {
            return true;
          }
        }

        return false;
      };
    },
    getCustomerShippingAddress() {
      return (item) => {
        return `${item?.customer?.shipping_address?.postal_code} ${item?.customer?.shipping_address?.city}`;
      };
    },
    getCarTypeExist() {
      return (item) => item.properties?.car_type?.length;
    },
    getCarChargingTypeExist() {
      return (item) => item.properties?.charging_opportunity?.length;
    },
    getUrgentReason() {
      return (item) => item.properties?.urgent_reason;
    },
    getChargingType() {
      return (charging_possibility) => {
        let humanizedType = '';
        if (charging_possibility == 'Ja') {
          humanizedType = this.$t('yes');
        }
        if (charging_possibility == 'Nein') {
          humanizedType = this.$t('no');
        }
        return humanizedType;
      };
    },
    getCarType() {
      return (car_type) => {
        let humanizedType = '';
        if (car_type == 'Voll elektrisches Auto')
          humanizedType = this.$t('car_type.electric');
        if (car_type == 'Hybrid-Auto')
          humanizedType = this.$t('car_type.hybrid');
        return humanizedType;
      };
    },
    latestStatusChangeFormatted() {
      return (item) => {
        return item.job.last_status_change
          ? this.$t('statuschange', {
              status_change_date: this.$moment(
                item.job.last_status_change,
              ).format('L'),
            })
          : this.$t('statuschange', {
              status_change_date: this.$moment(item.job.created_date).format(
                'L',
              ),
            });
      };
    },
    shouldDisplayQuote() {
      return (item) => {
        const statusesArray = [
          JobStatusEnum.PROPOSAL_APPROVAL_PENDING,
          JobStatusEnum.ACCEPTED,
          JobStatusEnum.ON_HOLD,
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.FINISHED,
          JobStatusEnum.OPEN,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.COMPLETED,
        ];
        if (
          statusesArray.includes(item.job.status) &&
          item.opportunity.brand_can_view_quote
        ) {
          return true;
        }
        return false;
      };
    },
    shouldDisplayBrandQuote() {
      return (item) => {
        const statusesArray = [
          JobStatusEnum.COMPLETED,
          JobStatusEnum.INVOICE_SENT,
          JobStatusEnum.OPEN,
          JobStatusEnum.CANCELLED,
          JobStatusEnum.ON_HOLD,
          JobStatusEnum.ACCEPTED,
          JobStatusEnum.FINISHED,
          JobStatusEnum.APPROVAL_PENDING,
          JobStatusEnum.CLOSED,
          JobStatusEnum.APPOINTMENT_CONFIRMED,
          JobStatusEnum.STARTED,
        ];
        return (
          item.job.status === JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING ||
          (statusesArray.includes(item.job.status) &&
            item.opportunity.brand_can_view_brand_quote)
        );
      };
    },
    shouldDisplayBrandActions() {
      return (item) => {
        return (
          item.job.status === JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING
        );
      };
    },
    shouldBeAbleToInvoice() {
      return (item) => {
        // https://app.asana.com/0/772623105799740/1201017931287722
        return ['0060800000uCOScAAO'].includes(item.opportunity.sfid);
      };
    },
    hasOwnInstaller() {
      return (job) => {
        return job?.job?.own_installer;
      };
    },
    computedFields() {
      if (this.associatedWithProject) {
        return [
          {
            label: 'flow-slas',
            key: 'days_since_last_status_change',
            sortable: true,
          },
          { label: this.$t('jobs.table.heading.id'), key: '_brand_ids' },
          {
            label: this.$t('jobs.table.heading.project-id'),
            key: 'project.name',
          },
          {
            label: this.$t('jobs.table.heading.project_owner'),
            key: 'project_manager',
          },
          {
            label: this.$t('jobs.table.heading.order-value'),
            key: 'job._price',
          },
          {
            label: this.$t('jobs.table.heading.customer'),
            key: 'customer._fullname',
          },
          {
            label: this.$t('projects.table.heading.car-delivery-date'),
            key: 'car_delivery',
            sortable: true,
            formatter: (value) =>
              value ? this.$moment(value).format('L') : '-',
          },
          {
            label: this.$t('jobs.table.heading.created-date'),
            key: 'job.created_date',
            sortable: true,
            formatter: (value) =>
              value ? this.$moment(value).format('L') : '-',
          },
          {
            label: this.$t('jobs.table.heading.appointment'),
            key: 'job',
            formatter: (value) =>
              value.appointment_start_timestamp
                ? this.getTimeRange(value)
                : '-',
          },
          { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
          {
            label: this.$t('jobs.table.heading.documentation'),
            key: '_actions',
            tdClass: 'text-nowrap',
          },
        ];
      }
      return [
        {
          label: 'flow-slas',
          key: 'days_since_last_status_change',
          sortable: true,
        },
        { label: this.$t('jobs.table.heading.id'), key: '_brand_ids' },
        {
          label: this.$t('jobs.table.heading.opportunity'),
          key: 'opportunity.name',
        },
        {
          label: this.$t('jobs.table.heading.order-value'),
          key: 'job._price',
        },
        {
          label: this.$t('jobs.table.heading.customer'),
          key: 'customer._fullname',
        },
        {
          label: this.$t('projects.table.heading.car-delivery-date'),
          key: 'car_delivery',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        {
          label: this.$t('jobs.table.heading.created-date'),
          key: 'job.created_date',
          sortable: true,
          formatter: (value) => (value ? this.$moment(value).format('L') : '-'),
        },
        {
          label: this.$t('jobs.table.heading.appointment'),
          key: 'job.appointment_start_timestamp',
          formatter: (value) =>
            value.appointment_start_timestamp ? this.getTimeRange(value) : '-',
        },
        { label: this.$t('jobs.table.heading.status'), key: 'job.status' },
        {
          label: this.$t('jobs.table.heading.documentation'),
          key: '_actions',
          tdClass: 'text-nowrap',
        },
      ];
    },
    downloadDisabled() {
      return this.totalRows === 0 || this.exportState != 'available';
    },
    statusesById() {
      return this.statuses.reduce((statuses, status) => {
        statuses[status.value] = status.text;
        return statuses;
      }, {});
    },
    statusesGroupedByName() {
      const byNames = this.statuses.reduce((statuses, status) => {
        if (!statuses[status.text]) statuses[status.text] = [];
        statuses[status.text].push(status.value);
        return statuses;
      }, {});
      return Object.keys(byNames).map((name) => ({
        text: name,
        value: byNames[name],
      }));
    },
    projectManagersGroupedByName() {
      const byNames = this.projectManagers.reduce((pms, pm) => {
        if (!pms[pm.name]) pms[pm.name] = [];
        pms[pm.name].push(pm.sfid);
        return pms;
      }, {});
      return Object.keys(byNames).map((name) => ({
        text: name,
        value: byNames[name].join(','),
      }));
    },
    canEditOrderEID() {
      return this.user?.account?.profiles.includes(
        AccountProfileEnum.EDIT_ORDER_EID,
      );
    },
    selectedProjectManager() {
      return (item) => {
        if (item && item.project_owners.length) {
          return item.project_owners.map((entry) => ({
            value: entry.sfid,
            text: `${entry.first_name} ${entry.last_name}`,
          }))[0];
        }
        return { value: '', text: '' };
      };
    },
    associatedWithProject() {
      return this.items.some(
        ({ project }) => project['id'] != null || project['name'] != null,
      );
    },
  },
  watch: {
    sortBy() {
      this.loadData();
    },
    sortDir() {
      this.loadData();
    },
    filterStatus(nextVal, prevVal) {
      if (JSON.stringify(prevVal) !== JSON.stringify(nextVal)) {
        this.$nextTick(() => this.loadData());
      }
    },
  },
  created() {
    this.loadData();
    axios.get(`/api/brand/opportunities`).then((response) => {
      const { data } = response;
      if (data.some((entry) => entry.brand_can_view_quote)) {
        this.advancedFilterOptions.push({
          label: 'Invoiced by brand',
          type: 'invoiced_by_brand',
          component: 'b-form-checkbox',
        });
      }
    });
    axios.get(`/api/brand/orders/statuses`).then(({ data }) => {
      this.statuses = data
        .filter((orderStatus) => {
          if (this.shouldShowProposalMenuItems) {
            return ![JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING].includes(
              orderStatus.job_status,
            );
          }
          return orderStatus;
        })
        .map((status) => ({
          text: status.brand_name[this.locale],
          value: status.job_status,
        }));
    });
    axios.get(`/api/brand/cancellation-reasons`).then((response) => {
      this.cancellations = response.data.reduce((cancellations, cr) => {
        cancellations[cr.id] = cr;
        return cancellations;
      }, {});
    });
    axios.get(`/api/brand/project-managers`).then((response) => {
      this.projectManagers = response.data.map((pm) => ({
        text: `${pm.first_name} ${pm.last_name}`,
        value: pm.sfid,
      }));
    });
  },
  methods: {
    editCustomerEid(item) {
      item.customer_number_editing = true;
      this.customerEid = item.customer.eid;
    },
    async setCustomerEid(job_api_id, item) {
      try {
        item.customer_number_loading = true;
        const res = await axios.put(
          `/api/brand/orders/${job_api_id}/customer-eid`,
          {
            job_api_id,
            customer_eid: this.customerEid,
            customer_id: item.customer.id,
          },
        );
        if (res.data.api_id)
          this.items = this.items.map((i) => {
            if (i.customer.id === item.customer.id) {
              i.customer.eid = this.customerEid;
            }
            return i;
          });
        item.customer_number_loading = false;
        item.customer_number_editing = false;
      } catch (error) {
        console.log(error.message);
      }
    },
    getTimeRange(job) {
      return (
        this.$moment(job.appointment_start_timestamp).format('L H:mm') +
        ' - ' +
        this.$moment(job.appointment_end_timestamp).format('H:mm')
      );
    },
    cancelEidEditing(item) {
      item.customer_number_editing = false;
    },
    async acceptQuote(item) {
      try {
        this.quote.saving = true;
        const payload = {
          preview: false,
          new_status: item.partner?.id
            ? JobStatusEnum.ACCEPTED
            : JobStatusEnum.OPEN,
          job_id: item.job.sfid,
        };
        const resp = await axios.post(`/api/brand/orders/set-status`, payload);
        this.refreshJob(item.job.sfid);
        this.quote.saving = false;
      } catch (e) {
        this.quote.saving = false;
        throw new Error(e.message);
      }
    },
    async rejectQuote(job_id) {
      try {
        this.quote.saving = true;
        const resp = await axios.post(`/api/brand/orders/set-status`, {
          preview: false,
          new_status: JobStatusEnum.CANCELLED,
          job_id,
        });
        this.refreshJob(job_id);
        this.quote.saving = false;
      } catch (e) {
        this.quote.saving = false;
        throw new Error(e.message);
      }
    },
    greaterDateFormatted(a, b) {
      const date = a && b ? (a > b ? a : b) : a ? a : b;
      return date
        ? this.$t('readyforinstallation', {
            installationdate: this.$moment(date).format('L'),
          })
        : '-';
    },
    async getQuotePath(job_id) {
      const resp = await axios.get(`/api/brand/orders/${job_id}/quote`);
      const path = `/api/media/file/${resp.data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
    async getBrandQuotePath(job_id) {
      const resp = await axios.get(`/api/brand/orders/${job_id}/brand-quote`);
      const path = `/api/media/file/${resp.data}?jwt=${this.access_token}`;
      window.open(path, '_blank').focus();
    },
    async invoiceOrder(job_id) {
      axios.put(`/api/brand/orders/${job_id}/invoice`).then(() => {
        this.refreshJob(job_id);
      });
    },
    searchQuery() {
      const searchQueryBase = this.job_id
        ? {
            search: this.job_id,
            status: null,
            project_manager: null,
            page_size: 1,
            page_number: 0,
          }
        : {
            page_size: this.perPage,
            page_number: 0,
            search: this.filterSearch.trim(),
            from_date: this.filterDateFrom,
            to_date: this.filterDateTo,
            status: this.filterStatus?.flatMap((s) => s.value),
            project_manager: this.filterProjectManager,
            sort_by: this.sortBy ? this.sortBy : null,
            sort_dir: this.sortDir ? 'desc' : 'asc',
            excluding: this.items.map(({ api_id }) => api_id),
            not_in_status: this.shouldShowProposalMenuItems
              ? [JobStatusEnum.BRAND_PROPOSAL_APPROVAL_PENDING]
              : [],
          };
      return this.filterUseAdvancedSearch && this.filterAdvancedSearch
        ? {
            ...searchQueryBase,
            ...{ search_json: JSON.stringify(this.filterAdvancedSearch) },
          }
        : searchQueryBase;
    },
    async refreshJob(job_sfid) {
      try {
        const { data: job } = await axios.get(`/api/brand/orders/${job_sfid}`);
        this.jobUpdated(job);
      } catch (error) {
        this.$bvToast.toast(
          error.response?.status || this.$t('_errors.network.details'),
          {
            title: this.$t('_errors.server.title'),
            variant: 'danger',
          },
        );
      }
    },
    jobUpdated(newJob) {
      this.items = this.items.map((item) => {
        if (item.job.sfid == newJob.job.sfid) {
          return {
            ...newJob,
            _showDetails: true,
          };
        } else {
          return item;
        }
      });
    },
    async loadData(append = false) {
      if (!append) {
        this.totalRows = null;
        this.items = [];
      }
      if (this.totalRows !== null && this.items.length >= this.totalRows) {
        return;
      }
      this.loading = (this.totalRows ?? this.perPage) - this.items.length;
      if (this.loading > this.perPage) {
        this.loading = this.perPage;
      }
      this.loading = true;
      Api.post(`/api/brand/orders`, this.searchQuery(), {
        autoCancelScope: `/api/brand/orders`,
      })
        .then((response) => {
          this.totalRows =
            this.items.length + response.data.pagination.total_count;
          const results = response.data.data.map((i) => ({
            ...i,
            customer_number_editing: false,
            customer_number_loading: false,
          }));
          if (!this.items.length) {
            this.items = [...results];
          } else {
            this.items.push(...results);
          }

          axios
            .post('/rating-api/averages/for-brand/job', {
              ids: [...response.data.data.map((entry) => entry.api_id)],
            })
            .then((res) => {
              this.jobRatings = res.data;
            })
            .catch(() => {
              // swallow error
            });
        })
        .catch(() => {
          // swallow error
        })
        .finally(() => {
          this.loading = 0;
        });
    },
    filterUpdated() {
      this.exportState = 'available';
      this.$nextTick(() => this.loadData());
    },
    jobHasDocs(job_view) {
      return Object.keys(job_view.files).length > 0;
    },
    async downloadReport() {
      this.exportState = 'loading';
      try {
        await axios.post(`/api/brand/orders/export`, this.searchQuery());
        this.exportState = 'success';
      } catch (error) {
        this.exportState = 'danger';
        this.exportError = error.response.data;
      }
      this.$root.$emit('bv::show::popover', 'download-report-btn');
      setTimeout(() => {
        window.addEventListener('click', this.hidePopover);
      }, 100);
    },
    hidePopover() {
      this.$root.$emit('bv::hide::popover', 'download-report-btn');
      window.removeEventListener('click', this.hidePopover);
    },
    editEID(job_id, eid, field) {
      this.edit_eid_modal.job_id = job_id;
      this.edit_eid_modal.eid = eid;
      this.edit_eid_modal.field = field;
      switch (field) {
        case InputTypes.ORDER_EID:
          this.edit_eid_modal.label = 'Order EID';
          break;
        case InputTypes.INVOICING_EID:
          this.edit_eid_modal.label = 'Invoice EID';
          break;
      }
      this.$refs.orderEidModal.show();
    },
    saveOrderEID() {
      axios
        .post(`/api/brand/orders/set-status`, {
          preview: false,
          new_status: null,
          job_id: this.edit_eid_modal.job_id,
          inputs: {
            [this.edit_eid_modal.field]: this.edit_eid_modal.eid,
          },
        })
        .then(() => {
          this.refreshJob(this.edit_eid_modal.job_id);
        });
    },
    async setProjectManager(projectId, pmId) {
      try {
        await axios.put(`/api/brand/projects/set-project-owner`, {
          project_id: `${projectId}`,
          contact_sfid: pmId,
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    compoundStateItems(job_view) {
      return mapDetails.call(
        this,
        job_view.job.states?.list?.[0]?.startsWith(job_view.job.status) &&
          job_view.job.states?.list?.[0].includes('.')
          ? job_view.job.states?.list
          : [],
        job_view.job.states?.details,
      );
    },
  },
};
</script>

<style lang="scss">
.gid-info-icon {
  font-size: 1em;
}
.gid-brand-eid-edit {
  font-size: 1em;
}
</style>
